import { safeMap } from '@src/utils/mapper';
import { get } from 'lodash';
import multipleAuthorsIcon from './images/multiple-authors-icon.png';

export const parseGraphResultToArray = (queryResult) =>
  safeMap(get(queryResult, 'allMarkdownRemark.edges'), (edge) => edge.node);
export const parseGraphResultToArrayNoEdges = (queryResult) =>
  safeMap(get(queryResult, 'allMarkdownRemark.nodes'), (node) => node.frontmatter);
export const extractBlogs = (queryResult) =>
  safeMap(get(queryResult, 'allMarkdownRemark.edges'), (edge) => edge.node.frontmatter);
export const filterGlossaryByKeywords = (queryResult, keywords) => {
  let glossaryEntities = [...queryResult];
  const words = keywords.trim().toLowerCase();
  if (keywords) {
    glossaryEntities = glossaryEntities.filter((glossary) => {
      return glossary.title.trim().toLowerCase().includes(words);
    });
  }
  return glossaryEntities;
};
export const parseGraphResultToArrayNoEdgesOnlyGlossaryBlog = (queryResult) =>
  safeMap(get(queryResult, 'getFromBlog.nodes'), (node) => node.frontmatter);
export const parseGraphResultToArrayNoEdgesOnlyNews = (queryResult) =>
  safeMap(get(queryResult, 'allMarkdownRemark.nodes'), (node) => node);

export const parseFilterAuthorsToObject = (authors, multipleAuthorsText) =>
  authors?.length > 1 || authors?.length === 0
    ? {
        avatar: multipleAuthorsIcon,
        name: multipleAuthorsText,
        path: '',
      }
    : {
        avatar: authors?.[0].avatar || authors?.[0].frontmatter?.avatar,
        name: `${authors?.[0].name}`,
        path: `${authors?.[0].path}`,
      };

const parseGraphResultsToArray = (queryResult) => {
  const entities = [];
  queryResult.edges.forEach((edge, index) => {
    const { frontmatter } = get(queryResult, `edges[${index}].node`);
    entities.push({
      keywords: frontmatter?.keywords,
      searchKeywords: frontmatter?.searchKeywords?.split(',') || [],
      link: frontmatter?.path,
      id: frontmatter?.caseStudyId,
      categories: frontmatter?.category,
      title: frontmatter?.caseStudyName,
      services: frontmatter?.serviceType,
      needs: frontmatter?.needs,
      client: frontmatter?.clientTypeName,
      description: frontmatter?.description,
      thumbnail: frontmatter?.thumbnailImage,
      shortDescription: frontmatter?.shortDescription,
    });
  });
  return entities;
};
const filterGroupType = (filterType, selectedTypes, entities) =>
  entities.filter((caseStudy) =>
    caseStudy[filterType].some((type) => selectedTypes.some((selectedType) => type === selectedType.name)),
  );
const isSearchedInGroupTypes = (services, categories, loweredSearchingKeyword, needs) =>
  [...services, ...categories, ...(needs || [])].some((keyword) =>
    keyword.toLowerCase().includes(loweredSearchingKeyword),
  );
export const filteredCaseStudies = (queryResult, searchedServiceType, searchedKeyWords) => {
  let entities = parseGraphResultsToArray(queryResult);
  if (searchedServiceType?.length) {
    entities = filterGroupType('services', searchedServiceType, entities);
  }
  if (searchedKeyWords) {
    const loweredSearchingKeyword = searchedKeyWords.trim().toLowerCase();
    entities = entities.filter(({ title, categories, services, needs }) => {
      return (
        title.toLowerCase().includes(loweredSearchingKeyword) ||
        isSearchedInGroupTypes(services, categories, loweredSearchingKeyword, needs)
      );
    });
  }
  return entities;
};
export const filteredExamplesOfOurWork = (
  queryResult,
  searchedServiceType,
  searchedCaseStudiesCategory,
  searchedKeyWords,
) => {
  let entities = parseGraphResultsToArray(queryResult);
  if (searchedCaseStudiesCategory) {
    entities = entities.filter((caseStudy) => {
      return caseStudy.categories.some((category) => category === searchedCaseStudiesCategory);
    });
  }
  if (searchedServiceType) {
    entities = entities.filter((caseStudy) => {
      return caseStudy.services.some((service) => service === searchedServiceType);
    });
  }
  if (searchedKeyWords) {
    const searchingKeywordsArray = searchedKeyWords.trim().split(' ');
    entities = entities.filter(({ keywords, title, categories, services, client, description }) => {
      return searchingKeywordsArray.some((searchingKeyword) => {
        const loweredSearchingKeyword = searchingKeyword.toLowerCase();
        return (
          // case when keyword matched
          keywords?.some((keyword) => keyword.toLowerCase().includes(loweredSearchingKeyword)) ||
          // case when title matched
          title.toLowerCase().includes(loweredSearchingKeyword) ||
          // case when serviceType matched
          services.some((service) => service.toLowerCase().includes(loweredSearchingKeyword)) ||
          // case when category matched
          categories.some((category) => category.toLowerCase().includes(loweredSearchingKeyword)) ||
          // case when description matched
          description.toLowerCase().includes(loweredSearchingKeyword) ||
          // case when clientTypeName matched
          client.toLowerCase().includes(loweredSearchingKeyword)
        );
      });
    });
  }
  return entities;
};

export const parseBlogAuthorsToArray = (authors) => {
  const authorDestination = [];
  if (authors) {
    for (const author of authors) {
      authorDestination.push({
        name: `${author.frontmatter.name.trim()} ${author.frontmatter.surname.trim()}`,
        avatar: author.frontmatter?.avatar,
        path: author.frontmatter?.path,
      });
    }
  }
  return authorDestination;
};

export const parseGraphBlogPostsToArray = (queryResult, author, category, keywords) => {
  let blogPostEntities = [];
  for (let i = 0; i < queryResult.edges.length; i++) {
    const {
      frontmatter: {
        title,
        path,
        date,
        thumbnail,
        shortDescription,
        authors,
        searchKeywords,
        blogKeywords,
        postCategories,
        postSubcategories,
        postTags,
      },
      wordCount,
    } = queryResult.edges[i].node;
    const authorDestination = parseBlogAuthorsToArray(authors);
    const postCategoriesDestination = [];
    if (postCategories) {
      for (const post of postCategories) {
        if (post && post.frontmatter) {
          postCategoriesDestination.push({
            name: post.frontmatter?.postCategoryName.trim(),
          });
        }
      }
    }
    const postSubCategoriesDestination = [];
    if (postSubcategories) {
      for (const post of postSubcategories) {
        if (post && post.frontmatter) {
          postSubCategoriesDestination.push({
            name: post.frontmatter?.postSubcategoryName.trim(),
          });
        }
      }
    }
    const postTagsDestination = [];
    if (postTags) {
      for (const postTag of postTags) {
        if (postTag && postTag.frontmatter) {
          postTagsDestination.push(postTag.frontmatter?.postTagName.trim());
        }
      }
    }
    blogPostEntities.push({
      title,
      path,
      date,
      thumbnail,
      shortDescription,
      authors: authorDestination,
      blogKeywords,
      searchKeywords: searchKeywords?.split(',') || [],
      postCategories: postCategoriesDestination,
      postSubcategories: postSubCategoriesDestination,
      words: wordCount?.words,
      postTags: postTagsDestination,
    });
  }
  if (author) {
    blogPostEntities = blogPostEntities.filter((blogPost) =>
      blogPost.authors.some((authors) => authors.name === author),
    );
  }
  if (category) {
    blogPostEntities = blogPostEntities.filter((blogPost) =>
      blogPost.postCategories.some((post) => post.name === category),
    );
  }
  if (keywords) {
    const keywordsArray = keywords.trim().split(' ');
    blogPostEntities = blogPostEntities.filter(({ blogKeywords, title, authors, postCategories }) => {
      return keywordsArray.some((keyword) => {
        const lowerCasedKeyword = keyword.toLowerCase();
        return (
          // case when keyword matched
          blogKeywords.some((blogKeyword) => blogKeyword.toLowerCase().includes(lowerCasedKeyword)) ||
          // case when title matched
          title.toLowerCase().includes(lowerCasedKeyword) ||
          // case when author's name matched
          authors.some(({ name }) => name.toLowerCase().includes(lowerCasedKeyword)) ||
          // case when post's category matched
          postCategories.some(({ name }) => name.toLowerCase().includes(lowerCasedKeyword))
        );
      });
    });
  }
  return blogPostEntities;
};
